.iconsContainer{
   display:flex;
   justify-content: flex-start;
   align-items: flex-start;
   gap:20px;
   padding-left:0;
}
.vertical{
   flex-direction: column;
   justify-content: center;
}

.iconContainer{
   list-style-type: none; /* Remove bullets */
   color:#eaeaea;
   padding:3px 0px;
   margin-top: 5px;
}
.iconContainer:hover {
   border-color: #8BC53F;
}
.icon{
   color:#eaeaea;
   width:20px;
}