/* book.module.css */
.book_container {
  max-height:500px;
  max-width:800px;
  overflow: hidden;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 0 auto;
}
.dark_mode {
  box-shadow: 5px 0px 10px rgba(255, 255, 255, 0.405);
}

.light_mode {
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Styling for the book component */
.book {
  position: relative;
  width: 400px ; 
  height: 500px;
  perspective: 1500px;
  /* Paper texture background */
  background-image: url('../../assets/images/bookcover.jpg');
  background-size:cover;
  /* Page curl effect on the bottom right corner */
  &::before {
    content: '';
    /* background: #f4e1d2; */
    transform: rotate(45deg);
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
   
  }
}

/* Styling for the page component */
.page {
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: #fffdf8;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* Shadow effect */
  background-size: cover;
  background-repeat: no-repeat;
}

.page:hover {
  cursor: pointer
}

/* Page flip animation */
.flipped .page {
  transform: rotateY(-180deg);
}

/* PAGE CONTENT */
.book_title {
  margin: 60px 20px 10px 20px;
  font-size: 30px;
  text-align: center;
}

.book_title_text {
  margin-bottom: 60px;
  font-size: 18px;
  color: rgb(146, 135, 135)
}

.book_title_icon {
  color: rgb(201, 196, 175)
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.arrow_icon {
  color: rgb(142, 136, 109);
  animation: blink 1.5s infinite; /* 1s is the duration, "infinite" makes it loop */
}


.page_title {
  font-weight: 900;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  height: 15%;
}

.page_text {
  padding: 0 10px;
  height: 80%;
  

}

.page_paragraph {
  text-indent: 30px;
  text-align: justify;
  font-size: 14px;
  padding-bottom: 10px;
  hyphens: auto;
  font-size: 11px;
}

.bottom_page {
  height: 5%;
  border-top: 1px solid #cae0c1d8;
  font-size: 10px;
  text-align: right;
  color: #444444d8;
}

@media screen and (max-width:440px){
  .book_title {
    font-size: 24px;  
  }
  
  .book_title_text {
    font-size: 14px;
  }
  .page_title{
    font-size: 14px;
  }
  .page_paragraph{
    font-size: 10px;
  }
  .bottom_page {
    font-size: 8px;
  }
}