
.header_scrolled {
   background-color: #353535ce;
   color: #fff;
}

/* Theme when menu is open */
.dark_headerMenu {
   background-color: #222222;
   color: rgb(159, 159, 159);
}

.headerMenu {
   background-color: #545353;
   color: white;
}

/* ****MAIN CONTAINER**** */
.header {
   position: sticky;
   top: 0;
   width: 100%; 
   z-index: 20;
}
.nav_bar{
   width: 100%;
   padding:10px 40px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;   
}

/* HEADER */
.logoContainer {
   display: flex;
   align-items: center;
   gap: 5px;
}

.name {
   font-size: 18px;
}

.nav {
   display: flex;
   gap: 30px;
   font-size: 16px;
}

.isActive,
.isInactive {
   text-decoration: none;
   color: inherit;
   position: relative;
   padding-bottom: 2px;
   font-size: 14px;
}

/* Nav Hover Effect */
.isInactive::before, 
.isActive::before {
   content: '';
   position: absolute;
   left: 0;
   bottom: 0;
   width: 0;
   height: 2.5px;
   background-color: #a2b998;
   /* Border color */
   transition: width 0.4s ease-in-out;
}

.isInactive:before{
   width: 0%;
}
.isActive:before {
   width: 100%;
}


.menu_icon {
   display: none;
   cursor: pointer;
}

/* ***END HEADER**** */

/* ***DROPDOWN MENU**** */
.menu_container{
   width: 100%;
   overflow: hidden;
}
.menu_open {
   height: calc(100vh);
   transition: height 0.6s ease-in-out;  
}

.menu_close {
   width: 100%;
   height: 0;
   transition: height 0.2s ease-in-out;
}

/* ***END DROPDOWN MENU**** */

@media screen and (max-width: 720px) {
   .nav_bar{
      padding:10px 20px;
   }
   .nav,
   .settings {
      display: none;
   }

   .menu_icon {
      display: block
   }
}
;