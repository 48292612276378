/* PARENT CONTANER */
.home {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensures the background covers the entire viewport */
  overflow-x: hidden; /* Hide horizontal scrollbar if necessary */ 
  transition: background-image 2s ease; /* Smooth transition for background image */
}
.homeDark{
  background-image: url('../../assets/images/home/background-dark.png');
  transition: background-image 2s ease; /* Smooth transition for background image */
}
.homeLight{
  background-image: url('../../assets/images/home/background.png');
  transition: background-image 2s ease; /* Smooth transition for background image */
}

/* COLOR THEME */
.dark{
  background-color: #392E3A;
  transition: background-color 1s, color 1s;
 }
 .light {
 background-color: #FEFAFF;
 transition: background-color 1s, color 1s;
 }

/* Top SECTION */
.nameContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 0 40px;
}

.name {
  font-size: 80px;
  margin-bottom: 0;
}

.jobTitle {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: light;
  font-size: 72px;
  line-height: 0.5;
}

.typeContainer {
  font-size: 18px;
  min-width: 600px;
  min-height: 140px;
  margin-left: 40%;
  border-left: 4px solid #a2b998;
  padding: 10px 20px;
}

/* INTRO SECTION */
.intro_container{
  border-top:2px solid #a2b998 ;
  border-left:2px solid #a2b998 ;
  padding-top: 150px;
  margin-top: -130px;
}

.img_container{
  position: relative;
  top: 0px;
  left:42%; 
  width:250px;
  display:flex;
  justify-content: center;
}

.introTitle{
  text-align: center;
}
.intro_subtitle{
  font-size: 20px;
}
.intro_description{
  text-align: justify;
}
/* END INTRO SECTION */


@media screen and (max-width:720px) {
  .nameContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
  }

  .name {
    font-size: 60px;
    padding-right: 20px;
  }

  .jobTitle {
    font-size: 48px;
    line-height: 0.7;
  }

  .typeContainer {
    margin-left: 30%;
    padding: 20px;
    min-height: 150px;
  }
  /* INTRO SECTION */
.intro_container{
  border-top:2px solid #a2b998 ;
  border-left:none ;
  margin-left:40px;
  margin-right:40px;
}
.img_container{
  left:32%
}
}

@media screen and (max-width:500px) {
  .name {
    font-size: 60px;
    line-height: 1;
  }
  .typeContainer{
    min-width:80vw;
    min-height:180px;
  }


  .jobTitle {
    padding-left: 5px;
    font-size: 28px;
    line-height: 1;
  }

  .typeContainer {
    margin-left: 40px;
    font-size: 14px;
  }

/* INTRO SECTION */
.intro_container{
  margin-left: 20px;
  margin-right: 20px;
  border-left: none ;
  padding-top: 120px;
  margin-top: -100px;
  padding:80px 0px;
}

.img_container{
  left:30%; 
  width:140px;
}
.img{
  width:120px
}
/* END INTRO SECTION */


}

