/* GENERAL STYLING */
*{
  margin:0;
  padding:0;
  box-sizing: border-box; 
}
.app{
  background-color: rgb(255, 250, 250);
  transition: background-color 1s, color 1s;
}

/* COLOR THEME */
.dark_mode{
  background-color:#392E3A;
  border-bottom: 0.5px solid rgb(49, 49, 49);
  /* color:rgb(187, 187, 187); */
  color: rgb(147, 147, 147);
  transition: background-color 1s, color 1s;
 }
 .light_mode {
 background-color: rgb(254, 250, 255);
 /* color:black; */
 color: rgb(134, 132, 132);
 transition: background-color 1s, color 1s;
 }
 /* COLOR THEME END */

 /* single Element*/
h1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600; 
}
h2, h3,h4{
  font-family: 'Nunito', sans-serif;
  font-weight: 600; 
}
h3{
  font-size:20px;
}

p, a , td, li{
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}
a{
  text-decoration: none !important;
  color: inherit !important;
  font-size: 18px ;
  font-family: 'Nunito', sans-serif !important;
}
a:hover{
  color:#a2b998 !important;
}

.primary{
  color:#a2b998
}
.secondary{
  color:#7A878C
}
.tertiary{
 color: #D8CAA1
}
.color4{
  color:#5A4D5B;
}
.color5{
 color: #FFFAFA
}
.color6{
  color:rgb(205, 189, 189)
}

.accent, .color7{
  color:rgb(150, 129, 129)
}
.accent:hover{
  cursor:pointer
}
.bg_color1{
  background-color:#a2b998
}
.bg_color2{
  background-color:#7A878C
}
.bg_color3{
  background-color: #D8CAA1
}
.bg_color4{
  background-color:#B79492
}
.bg_color5{
 color: #FFFAFA
}
.bg_color6{
  background-color:#75896d
}

.dark_mode .velvet {
  color: rgb(222, 208, 208);
}
.light_mode .velvet{
  color: rgb(107, 84, 91);
}

.width_medium{
  max-width: 850px;
  margin: 0 auto;
  padding:100px 80px;
}

.small_title{
  font-size:20px;
  margin-bottom: 0px;
}
.small_text{
  font-size:12px;
  overflow-wrap: break-word;
}
.medium_text{
  font-size:15px;
  overflow-wrap: break-word;
}

/*  PAGE and SECTIONS */
.main_container {  
  width: 100%;
  min-height: calc(100vh - 50px);
}

.intro_container {  /* use for the top section of a page (except resume) */
  margin:0 auto; 
  padding:80px 20px 20px 40px;
  height:calc(100vh - 50px);
  display:flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}


.title_container{ /* Each top page as a title container */
  padding-bottom: 40px;
  align-self: flex-end;
}

.title {
  font-size: 80px; 
  margin-bottom: 0;  
}

.subtitle {
  color: #b2b4b2;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: light;
  font-size: 74px; 
  line-height: 0.5;
}



@media screen and (max-width:720px){
 .intro_container{
   padding-left: 20px;
   padding-right: 20px;
 }

 .title {
   font-size: 50px; 
 }
 .subtitle {
   font-size: 45px; 
   line-height: 0.7;
 }
 p, a , td, li{
  font-size: 14px;
}
a{
  font-size: 14px !important;
}
.medium_text{
  font-size:14px;
}

}

@media screen and (max-width:480px){
 .title {
   font-size: 45px; 
    line-height: 1;
 }
 .subtitle {
   padding-left: 5px;
   font-size: 30px; 
   line-height: 0.7;
 }
 p{
  font-size: 14px;
 }

 .small_title{
  font-size:18px;

}
.small_text{
  font-size:11px;
}
.medium_text{
  font-size:14px;
}

}











