.projects_title {
   text-align: center;
   padding: 40px 0;
}

.image_container {
   width:50%;
}

.description_container {
   height: 100%;
   width: 50%;
   padding: 0 20px;
   display: flex;
   flex-direction: column;
   justify-content:center;
   align-items:center ;
}

.title {
   text-align: center;
}
.subtitle {
   padding-bottom: 20px;
   text-align: center;
   font-size: 16px;
}

.text{
   text-align: justify;
   hyphens: auto;
   font-size: 14px;
}
.features{
   font-size: 14px ;
}


.buttons {
   margin: 20px 0;
   gap: 20px;
   display: flex;
   justify-content: space-around;
   align-items: center;
}

.button a {
   padding: 10px;
   border-radius: 20px;
   text-decoration: none;
   font-size: 10px;
   max-width: 180px;
   text-align: center;
   font-weight: 500;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* COLOR THEME  */
.darkButton a {
   color: #eaeaea;
   border: 1px solid #eaeaea;
}

.brightButton a {
   color: #0b0b0b;
   border: 1px solid #ffffff;
}

@media screen and (max-width:720px) {
   .card {
      flex-direction: column;
      width: 100%;
   }

    .description_container {
      height: 100%;
      width: 100%;
   }
   .image_container{
      width:70%;
   }
}


/* PROJECT CARD END */


/* HORIZONTAL GALLERY */
.horizontal_gallery {
   gap: 0px;
   display: flex;
   align-items: center;
   overflow: hidden;
   position: relative;
   width: 100%;
   padding: 0 20px;
}

.gallery_container {
   display: flex;
   overflow-x: scroll;
   scroll-snap-type: x mandatory;
   scrollbar-width: none;
   -ms-overflow-style: none;

}

.caroussel{
   padding:40px 0;
   min-height:400px;
}

.card {
   display: flex;
   align-items: center;
   gap:20px;
   border-radius: 4px;
   margin: 10px auto;
   margin-bottom: 80px;
   width:75%;  
   flex-shrink: 0;
   padding: 16px;
}

.gallery-container::-webkit-scrollbar {
   display: none;
}

@media screen and (max-width:400px) {
   .card {
      width: 90%;
      padding: 5px;
   }

}