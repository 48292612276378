.dark_mode{
   background-color: #000000; 
   color:rgb(193, 193, 193);
   transition: background-color 0.6s, color 0.6s ;

}
.light_mode{
   background-color: #2e2e2e;
   color:rgb(193, 193, 193);
   transition: background-color 0.6s, color 0.6s;
}

.footer{
   padding-top:40px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   align-items: flex-start;
}
.container{
padding:20px 50px 80px 40px;
}
.leftContainer{
   text-align: left;
}


/* Logo Section */
.portfolio{
   padding-left: 5px;
   font-family: 'Montserrat', sans-serif;
   font-weight: bold;
   font-size: 16px;
}
.name{
   font-size: 18px;
   font-weight: bold;
}

/* Contact Section */
.contact a{
   color:#eaeaea;
   text-decoration: none;
   font-size: 12px;
}

.contact :hover {
   color:#8BC53F
}

/* Social Media Section */
.title{
   padding-bottom: 20px;
}
.subTitle{
   font-size: 12px;
}

.settings{
   display: flex;
   gap:20px;
   justify-content: flex-end;
   align-items: baseline;
}

/* Author Section */
.author{
   display:flex;
   justify-content: space-between;
   width:100%;
   text-align: center;
   border-top: 1px solid #777777;
   padding:15px 60px 0px 60px;
}
.tool{
   text-align: center;
}


@media screen and (max-width:500px) {
   .footer{
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
   }
   .container{
      padding:20px 50px 80px 40px;
      display:flex;
      flex-direction: column;
      align-items: center;
      }
      /* Author Section */
.author{
flex-direction: column;

   text-align: center;

}
.tool{
   text-align: center;
}
}