.component_container{
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin:0 auto;
   padding: 40px;
   gap:40px;
}
.title_container{
   padding:0 40px;
}
.skillset_container{
   display:flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   gap: 60px;

}

.skillset{
   display:flex;
   place-items: center;
   flex-direction: column;
   gap:10px; 
}
.light{
   color: rgb(140, 113, 121)
}
.dark{
   color:rgb(223, 206, 206);
}

.name{
   white-space: nowrap;
}


.expertise_items{
   display:flex;
   justify-content: center;
   align-items: center;
  padding:20px 0;
}
.expertise_item_title{
   text-align: center;
}

@media screen and (max-width:660px){
   .component_container{
      flex-direction:column
   }
}

@media screen and (max-width: 410px){
   .component_container{
     padding:5px
   }
   .skillset_container{
      gap:5px;

   }
   .skillset{
      width:30%;
   }
}