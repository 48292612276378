/* COLOR THEME */
.dark{
   background-color: #392E3A;
   transition: background-color 1s, color 1s;
  }
  .light {
  background-color: #FEFAFF;
  transition: background-color 1s, color 1s;
  }


.box_container{
   display:flex;
   justify-content:center;
   width:80%;
   flex-wrap: wrap;
   margin:0 auto;
   gap:10px; 
}
.box{
   width:40%;
   height:300px;
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   padding:20px 40px;
   text-align: justify;
   hyphens: auto;
   gap: 10px;
   
}
.box:hover{
   filter: brightness(0.9);
}
.title{
   text-align: center;
}

@media screen and (max-width:720px){
   .solutions_container{
      width:100%;      
   }
   .box_container{
      display:flex;
      justify-content:center;
      width:100%;
      flex-wrap: wrap;
      margin:0 auto;
      gap:10px; 
   }
   .box{
      height:300px;
      padding:20px 20px;
      gap: 10px;
      justify-content: center;
   }
}
@media screen and (max-width:480px){
   .component_container{
      padding-top:40px;
   }
   .box_container{
      gap:0;
      padding:0 10px;     
   }
   .title_container{
      margin: 0 20px 80px 20px;
      border-left: none; 
   }
   .solutions_title{
      top: -20px;  
      width:200px;
   }

   .solutions_container{
      width:100%;      
   }
   .box_container{
      display:flex;
      justify-content:center;
      width:100%;
      flex-wrap: wrap;
      margin:0 auto;
      gap:10px; 
   }
.box{  
   width:100%;
   height:300px;
   padding:10px 20px;
   justify-content: center;
}
}
   
