.light{
  color: rgb(140, 113, 121)
}
.dark{
  color: rgb(223, 206, 206);
}

 @keyframes blink {
   0% { opacity: 1; }
   50% { opacity: 0; }
   100% { opacity: 1; }
 }
 .link{
  display: flex;
  justify-content: center;
  align-items: center;

 }
 
 .icon {
   animation: blink 1.5s infinite; /* 1s is the duration, "infinite" makes it loop */
   position:relative;
   top:-8px
 }

@media screen and (max-width: 500px) {
  .container{
    height:150px;
  }
}