.expertise_container {
   max-width: 850px;
   margin: 0 auto;
}

.card {
   display: flex;
   flex-direction: column;
   justify-content: baseline;
   align-items: flex-start;
   gap: 4px;
   padding: 20px 20px
}

.icon {
   margin-bottom: 10px;
   margin-left: 20px;
}

.title_container {
   text-align: center;
   margin: 0 0 80px 0;
   border-bottom: 2px solid #A2B998;
   border-right: 2px solid #A2B998;
}

.expertise_title {
   margin: 0 auto;
   position: relative;
   bottom: -18px;

   width: 250px;
}

/* COLOR THEME */
.dark {
   background-color: #392E3A;
   transition: background-color 1s, color 1s;
}

.light {
   background-color:#FEFAFF;
   transition: background-color 1s, color 1s;
}


@media screen and (max-width:500px) {
   .expertise_container {
      max-width: 100%;
      margin: 0 auto;
      padding: 0 5px;
   }

   .icon {
      margin-bottom: 10px;
      width: 25px;
      margin-left: 20px;
   }


   .title_container {

      text-align: center;
      margin: 0 10px 80px 10px;
      border-bottom: 2px solid #A2B998;
      border-right: none;
   }

   .expertise_title {
      width: 140px;
   }
}