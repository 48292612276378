table {

   height: 100%;
   border-collapse: collapse;
   margin: 0;
   padding: 0;
   border-top: 1px solid grey;
 }
 
 td {
   padding: 20px;
 }
 
 .mainCol {
   height: 70%;
 }
 
 .nav {
   height: 100%;
   font-family: 'Roboto Condensed', sans-serif;
   padding-left: 20px;
 }
 
 .nav_link {
   font-size: 40px !important;
   display: block;
   height: 20%;
 }
 
 .side {
   vertical-align: top;
   text-align: center;
   border-left: 1px solid grey;
   width: 30px;
   padding: 20px 10px;
 }
 
 .settings,
 .social_media {
   padding: 20px 0;
   height: 50%;
   border-bottom: 1px solid grey;
   border-top: 1px solid grey;
 }


 .toggle {
   padding-bottom: 20px;
 }
 
 .bottom {
   height: 30%;
   border-top: 1px solid grey;
   vertical-align: top;
 }
 @media screen and (max-width: 720px) {
  
  td {
  width:100%;
  }
  
  .mainCol {
    height: 85%;
  }
  
  .nav_link {
    font-size: 60px !important;
    display: block;
    height: 20%;
  }

  .social_media {
    margin-right:20px
    }
  
}
 
 @media screen and (max-width: 400px) {
  .nav_link {
    font-size: 30px !important;
  }
  .mainCol {
    height: 70%;
  }
  .social_media {
    margin-right:15px
    }
 }
 