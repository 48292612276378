.resume_container{
   width:100%;
   display:flex;
   flex-direction: column;
   place-items: center;
   gap:40px;
   margin:60px 0;
   padding:  20px;
}

.resume_image{
   width:100%;
   max-width: 900px;
}
 .intro_container{
   height:calc(45vh);
   display:flex;
   justify-content:flex-end;
   align-items:flex-end;
 }
 .title_container{
   padding:0 40px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items:flex-end;
   
 }
 .subtitle{
   padding-bottom: 20px;
 }