.button a {
   margin:0 auto;
   padding: 10px;
   border-radius: 8px;
   text-decoration: none;
   font-size: 12px;
   max-width:180px;
   text-align: center;
   font-weight:500;
   display:flex;
   justify-content: center;
   align-items: center;
   border:1px solid #eaeaea;
}
.button :hover{

}

/* COLOR THEME  */
.darkButton a{
   border:1px solid #eaeaea;
}


