.section{
  width: 100%;
  margin:200px auto;
  display:flex;
  flex-direction: column;
  gap:40px;
 }

 @media screen and (max-width: 500px){
.section{
  margin:100px 0px;
}
 }