.dark_name {
   color: rgba(96, 77, 96, 0.8);
   transition: background-color 1s, color 1s;
}

.light_name {
   color: rgba(241, 239, 239, 0.8);
   transition: background-color 1s, color 1s;
}

.intro_container {
   /* use for the top section of a page */
   margin: 0 auto;
   flex-direction: column;
   padding: 80px 20px 20px 40px;
   height: calc(100vh - 50px);
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-end;
   align-items: flex-start;
}

.title_container {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   gap: 20px;
   margin: 40px 0;
   flex-wrap: wrap;
   z-index: 1;
}

.description_container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   max-width: 850px;
   margin: 0 auto;
}

.decription_text {
   text-align: justify;
   font-size: 22px;
}

.name {
   line-height: 40px;
   font-size: 150px;
   z-index: 0;
   text-align: left;
   margin-bottom: 100px;
}

.category_list {
   max-width: 850px;
   margin: 0px auto;
   padding:20px 0;
   display: flex;
   gap: 40px;
   justify-content: space-evenly;
}
.nav_container{
   margin:0 auto;
   position:sticky;
   top:45px;
   z-index:10;
}

@media screen and (max-width:720px) {
   .name {
      font-size: 80px;
   }

   .description_container {
      padding: 40px;
   }
   .decription_text {
      font-size: 18px;
   }
}

@media screen and (max-width:480px) {
   .title_container{
      justify-content: flex-start;
   }

   .name {
      font-size: 80px;
      line-height: 65px;
      margin-bottom: 50px;
   }

   .description_container {
      padding: 10px 25px;
   }
   .decription_text {
      font-size: 16px;
   }
   .nav_container{
      width:100%;
      margin:0 auto;
      position:static;

   }
   .category_list {
      margin: 0px 40px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
   }
}